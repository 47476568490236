<template>
  <Container style="height: 100%; display: flex; align-items: center; justify-content: center">
    <CardSection>
      <Danger v-if="!loading" icon="false">
        <template #title>One last step!</template>
        <p>Please confirm that you are a human by clicking the checkbox below!</p>
        <CardList>
          <CardListField>
            <span> Click checkbox to finalize your request </span>
            <div>
              <font-awesome-icon icon="arrow-right" />
              <LoadingIndicator v-if="!showCheckbox" />
              <Checkbox class="info inline" v-else v-model="checked" />
            </div>
          </CardListField>
        </CardList>
      </Danger>
      <div class="flex items-center justify-center p-5" v-else>
        <LoadingIndicator />
      </div>
    </CardSection>
    <CardSection>
      <template #title>Why do we do this?</template>
      <p>
        The web is full of bots and spiders that fill out forms automatically. Most forms will
        receive on average 10-15 per day. Some can receive as many as 10-15 per minute. Having a lot
        of junk requests hurts our ability to respond to real requests and causes a lot of extra
        work to delete. We appreciate your help in fighting the spam!
      </p>
    </CardSection>
  </Container>
</template>

<script>
import PageMixin from '../mixins/Page'
import LoadingIndicator from '../ui/LoadingIndicator.vue'
import Button from '../mixins/Button'

export default {
  name: 'LeadFinalization',
  components: { LoadingIndicator },
  mixins: [PageMixin, Button],

  watch: {
    checked(b) {
      if (b) {
        this.submitLead()
      }
    }
  },

  methods: {
    async submitLead() {
      this.addLoading()

      const data = JSON.parse(this.$route.query.data) || {}

      try {
        const { object } = await this.$store.dispatch('ajax', {
          path: '/lead_rotation/finalizeLead',
          data
        })

        const recd = object.received

        if (recd.success_url) {
          this.$store.dispatch('openLink', {
            url: recd.success_url,
            newWindow: false
          })
        } else {
          this.$store.dispatch('to', '/pub/leadReceived')
        }
      } catch (e) {
        this.$store.dispatch('alert', {
          error: true,
          message: e.userMessage || e.message
        })
      } finally {
        this.endLoading()
      }
    }
  },

  data() {
    return {
      showCheckbox: 0,
      checked: 0
    }
  },

  async mounted() {
    await c.throttle(() => {}, { delay: 2000 })

    this.showCheckbox = 1
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.section--container {
  @media (pointer: coarse) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: revert;
    /* mobile */
  }
  @media (pointer: fine), (pointer: none) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* desktop */
  }
  @media (pointer: fine) and (any-pointer: coarse) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* touch desktop */
  }
}
</style>
